<template>
  <div>
    <div class="flex xs12 pb-5">
      <p class="display-1 text--center mb-2">
        {{ $t("auth.set_admin_password_title") }}
      </p>
      <p class="display-2 text--center">{{ $t("auth.set_admin_password") }}</p>
    </div>

    <form @submit.prevent="onsubmit" class="login">
      <p class="mb-4">{{ $t("auth.set_admin_password_text") }}</p>
      <va-input
        class="mb-3"
        v-model="instantiateForm.password"
        type="password"
        :label="$t('auth.password')"
        :error="!!passErrors.length"
        :error-messages="passErrors"
      />

      <va-input
        class="mb-3"
        v-model="instantiateForm.passwordAgain"
        type="password"
        :label="$t('auth.password_again')"
        :error="!!pass2Errors.length"
        :error-messages="pass2Errors"
      />

      <div class="row justify--center mt-3">
        <va-button type="submit" class="my-0">{{
          $t("auth.submit")
        }}</va-button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "instantiate",
  data() {
    return {
      instantiateForm: {
        password: "",
        passwordAgain: "",
      },
      passErrors: [],
      pass2Errors: [],
    };
  },
  created() {
    this.backend
      .isInstantiated()
      .then((result) => {
        if (result === true) {
          // console.log('already isInstantiated')
          this.$router.push("/auth");
        }
      })
      .catch((err) => {
        // console.log('error getting is isInstantiated')
        this.$router.push("/auth");
      });
  },
  computed: {
    formReady() {
      return !this.passErrors.length && !this.pass2Errors.length;
    },
  },
  methods: {
    onsubmit() {
      this.passErrors = this.instantiateForm.password
        ? []
        : [this.$t("auth.password_not_present")];
      this.pass2Errors = this.instantiateForm.passwordAgain
        ? []
        : [this.$t("auth.password_not_present")];
      if (
        this.instantiateForm.password &&
        this.instantiateForm.passwordAgain &&
        this.instantiateForm.password !== this.instantiateForm.passwordAgain
      ) {
        this.passErrors = [this.$t("auth.passwords_not_match")];
        this.pass2Errors = [this.$t("auth.passwords_not_match")];
      }
      if (!this.formReady) {
        // console.log('form is invalid')
        return;
      }
      this.backend
        .instantiate(this.instantiateForm)
        .then((result) => {
          if (result === false) {
            // console.log('error on instantiate')
            this.launchToast(
              this.$t("auth.set_admin_password_error"),
              "warning"
            );
          } else {
            this.$router.push("/auth");
          }
        })
        .catch((err) => {
          // console.log('error getting response')
          this.launchToast(this.$t("auth.set_admin_password_error"), "danger");
        });
    },
    launchToast(text, col) {
      this.$vaToast.init({
        message: text,
        position: "top-left",
        duration: 4000,
        fullWidth: false,
        color: col,
      });
    },
  },
};
</script>

<style lang="scss"></style>
